.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.logo img {
  /* Increase the size of the logo */
  height: 100px; /* Adjust the value to your preferred height */
  width: auto; /* Maintain aspect ratio */
}


/* Your existing CSS */

/* Media query for large screens */
@media screen and (min-width: 1024px) {
  .logo img {
    height: 200px; /* Increase the height for large screens */
    /* margin-bottom: 36px; */

  }
}

@media screen and (min-width: 1024px) {
  .navbar {
    height: 100px;
    /* border: 2px solid red; Temporary visual indicator for debugging */
  }
}

#navbarNavDropdown {
  height: 100px; /* Adjust this value to the desired height */
}


.smooth-scroll {
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  transition: all 0.3s ease-out;
  scroll-behavior: smooth;
}

.smooth-scroll:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentColor;
  transform: translateY(100%);
  transition: all 0.3s ease-out;
}

.smooth-scroll:hover:before {
  transform: translateY(0);
}

.smooth-scroll:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.smooth-scroll:active {
  transform: translateY(1px);
}

.underline-on-active {
  position: relative;
}

.underline-on-active::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 2px; 
  background-color: #a98004; 
  bottom: -2px; 
  left: 25%;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.underline-on-active.active::after,
.underline-on-active:hover::after {
  transform: scaleX(1);
}

.underline-on-active:hover span {
  color: #a98004;
}

.underline-on-active.active span
 {
  color: #d0d6dd; 
}
