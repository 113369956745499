.opacity-reduced {
    opacity: 0.5; 
    width: 300px;
}

@media (max-width: 426px) {
    .subscribeform {
      display: none;
    }
}

@media (max-width: 426px) {
    .footerAboutus {
      display: none;
    }
}

@media (max-width: 426px) {
    .footerContactus {
      display: none;
    }
}