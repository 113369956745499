.mainbanner {
    background-image: url("../img/home/pcHomeBanner.jpg");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    height: 550px; 
}

@media (max-width: 426px) {
    .mainbanner {
        background-image: url("../img/home/mobileHomeBanner.jpg");
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        height: 800px; 
    }
}

@media (min-width: 427px) and (max-width: 768px) {
    .mainbanner {
        background-image: url("../img/home/pcHomeBanner.jpg");
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        height: 500px; 
    }
}

.bannerTiles {
    background-color: rgba(0, 0, 0, 0.8);
} 

@media (max-width: 426px) {
    .bannerTiles {
        padding-top: 10px;
    }
}

@media (min-width: 769px) and (max-width: 1439px) {
    .bannerTileText {
        font-size: 12px;
    }
}

@media (min-width: 769px) and (max-width: 1025px) {
    .videoThumbnail {
        width: 300px;
        height: auto;
    }

    .mainbanner {
        background-image: url("../img/home/pcHomeBanner.jpg");
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        height: 580px; 
    }
}

.videoThumbnail {
    background-image: url("https://t4.ftcdn.net/jpg/04/95/70/97/360_F_495709752_VdzrXV6DuF7EOCH5ruzMWLsZvBZG9sY2.jpg");
    height: 200px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    margin-top: -10px;
}

@media (max-width: 768px) {
    .videoThumbnail {
        display: none;
    }
}

.homeImgone {
    background-image: url("../img/home/whatweofferbg.png");
    height: 400px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

@media (max-width: 426px) {
    .homeImgone {
        background-image: url("../img/home/whatweofferbg.png");
        height: 900px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}

@media (min-width: 427px) and (max-width: 768px) {
    .homeImgone {
        background-image: url("../img/home/whatweofferbg.png");
        height: 800px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}

.innerText {
    background-color: rgba(198, 125, 15, 0.8);
}  

.offerOne {
    background-image: url("../img/home/stock.jpg");
    height: 200px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

.offerTwo {
    background-image: url("https://www.goldmansachs.com/intelligence/pages/articles/real-estate-may-still-offer-a-hedge-for-investors/800x450.jpg");
    height: 200px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

.offerThree {
    background-image: url("../img//home/server.jpg");
    height: 200px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

.offerFour {
    background-image: url("../img//home/media.jpg");
    height: 200px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

@media (min-width: 769px) and (max-width: 1025px) {
    .offerOne {
        background-image: url("../img/home/stock.jpg");
        height: 100px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
    
    .offerTwo {
        background-image: url("https://www.goldmansachs.com/intelligence/pages/articles/real-estate-may-still-offer-a-hedge-for-investors/800x450.jpg");
        height: 100px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
    
    .offerThree {
        background-image: url("../img//home/server.jpg");
        height: 100px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
    
    .offerFour {
        background-image: url("../img/home/media.jpg");
        height: 100px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}

.locationsBg {
    background-image: url("../img/home/map.png");
    height: 700px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}

@media (max-width: 426px) {
    .locationsBg {
        background-image: url("../img/home/map.png");
        height: 220px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}

@media (min-width: 427px) and (max-width: 770px) {
    .locationsBg {
        background-image: url("../img/home/map.png");
        height: 380px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}

@media (min-width: 771px) and (max-width: 1025px) {
    .locationsBg {
        background-image: url("../img/home/map.png");
        height: 500px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}

@media (min-width: 1026px) and (max-width: 1439px) {
    .locationsBg {
        background-image: url("../img/home/map.png");
        height: 400px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }
}


.offerCol {
    margin-top: -20px;
}