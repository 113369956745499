@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@font-face {
    font-family: 'Open Sans';
    src: url('https://fonts.gstatic.com/s/opensans/v36/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2') format('woff2');
}

.font-primary {
    font-family: 'Open Sans';
}

@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-stretch: 100%;
    src: url('font/Gotham-Medium.woff2') format('woff2');
}

.font-secondary-medium {
    font-family: 'Gotham-Medium';
    font-weight: 400;
}

@font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-stretch: 100%;
    src: url('font/Gotham-Book.woff2') format('woff2');
}

.font-secondary-book {
    font-family: 'Gotham-Book';
    font-weight: 400;
}

#example-collapse-navbar ul li.active,
#example-collapse-navbar ul li.active button,
#example-collapse-navbar ul li.active button svg,
#example-collapse-navbar ul li a.active {
    color: #BA902E;
}

/* Configure this in Tailwind.config.js */

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}
  
.animate-fadeIn {
    animation: fadeIn 1s ease-in forwards;
}
  
.animate-fadeInUp {
    animation: fadeInUp 1s ease-in forwards;
}
  
/* Animation text slide up 
.fadein {
    opacity: 0;
    transition: all 1.5s;
    transform: translate(0, 100px);
}

.fadein.visible {
    opacity: 1;
    transform: translate(0, 0);
}
*/
/* images marquee */

div.marquee>span>img {
    height: 96px;
}

.logoMarqueeSection>div>div {
    min-height: 0;
}

.marquee-wrapper {
    display:  inline-block;
    white-space: nowrap;
}

.marquee {
    display:  inline-block;
    white-space: nowrap;
    position: relative;
    transform: translate3d(0%, 0, 0);
    animation-name: marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.marquee span {
    display:  inline-block;
    white-space: nowrap;
    padding-right: 3rem;
}

.marquee-wrapper:hover .marquee {
    animation-play-state: paused !important;
}

@keyframes marquee {
    0% {
        transform: translate3d(0%, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

/* images marquee */

@media only screen and (min-width: 320px) and (max-width: 440px)  {
    div.marquee>span>img {
        height: 80px;
    }
}

@media only screen and (min-width: 441px) and (max-width: 768px)  {
    div.marquee>span>img {
        height: 86px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1440px)  {
    div.marquee>span>img {
        height: 90px;
    }
}