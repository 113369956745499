.marquee {
    animation-duration: 57s;
}

.aboutBanner {
    background-image: url("https://c.myholidays.com/blog/blog/content/images/2021/12/Places-To-Visit-In-Manama-min.webp");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    height: 500px; 
}


@media (max-width: 426px) {
    .shahidimage {
      display: none;
    }
}
  